import React, {useState, useEffect, useRef, Fragment} from 'react'
import Drawer from '@material-ui/core/Drawer'
import { Button, Divider, Grid, Typography, Tooltip, TextField, Slider} from '@material-ui/core'

import Swal from 'sweetalert2'
import LockIcon from '@material-ui/icons/Lock'
import Close from '@material-ui/icons/Close'
import DetailIcon from '@material-ui/icons/Apps'
import { FilterOutline } from 'react-ionicons'


export default function SitebarFiltroInventory(props){

    const [open, setOpen] = useState(false)
   
    const toggleDrawer = () =>{
        setOpen(!open)
    }
    
    return <div>
    <React.Fragment >
        <Tooltip title = 'Buscar registros'>
            <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}} onClick={()=>{
               setOpen(!open)
                 
            }} full><FilterOutline
            color={'#00000'} 
            height="24px"
            width="24px"
            />    
            </Button>
        </Tooltip>
        <Drawer
             
          disableEscapeKeyDown
          anchor={'right'}
          open={open}
          hideBackdrop
          onClose={()=>setOpen(!open)}
        >
            <div style ={{width: "100%", height : '67px', backgroundColor:"#37474F"}}></div>
            <div style ={{width: "100%",  backgroundColor:"#CFD8DC", textAlign:'center', display: 'flex', padding : '10px'}}>
                <div style={{width : '80%', textAlign:'left', alignContent : 'flex-start'}}>
                  
                </div>
                <div style={{width : '20%'}}>
                    <Button fullWidth onClick={()=>setOpen(!open)}><Close/></Button>
                </div>
            </div>
          <div id='div-side-menu' style ={{
            width : '500px', 
            padding : '50px', 
            
          }}>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Chave de Instalação'
                            fullWidth
                            value={props.chaveInstalacao}
                            onChange={(e)=>props.setChaveInstalacao(e.target.value)}
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Cliente'
                            value={props.razaoSocial}
                            onChange={(e)=>props.setRazaoSocial(e.target.value)}
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>
                
                <br/>

                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Modelo'    
                            value={props.modelo}
                            onChange={(e)=>props.setModelo(e.target.value)}                        
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>

                <br/>

                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Marca'
                            value={props.marca}
                            onChange={(e)=>props.setMarca(e.target.value)}                            
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>
                <br/>

    
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Patrimônio (PAT)' 
                            value={props.pat}
                            onChange={(e)=>props.setPat(e.target.value)}                           
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>


<br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Serial Number' 
                            value={props.bios}
                            onChange={(e)=>props.setBios(e.target.value)}                           
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>
                <br/>

                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Sistema Operacional' 
                            value={props.sistemaOperacional}
                            onChange={(e)=>props.setSistemaOperacional(e.target.value)}                           
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>

                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <Typography>Dia(s) da última comunicação</Typography>
                        <Slider
                           value={props.diasPesquisa}
                           sx={{
                            width: 300, // Define a largura em pixels
                          }}
                            onChange={props.handleSliderChange}
                        />
                        <Typography style={{fontSize : '12px', color:'red'}} variant='caption'>{props.diasPesquisa == 0 ? 'Última comunicação hoje' : 'Última comunicação há ' + props.diasPesquisa+' dia(s)'}</Typography>
                    </Grid>
                </Grid>

               
                <br/>
              
                <Grid container spacing = {1}>
                    <Grid item xs = {8}>
                        <Button size='large'  fullWidth variant='contained' color='primary' onClick={()=>{
                            
                            props.serchData();
                            setOpen(!open)
                        }}>Aplicar</Button>
                    </Grid>
                    <Grid item xs = {4}>
                        <Button size='large' fullWidth variant='contained' color='secondary' onClick={()=>{
                           
                            props.resetFilter()
                            setOpen(!open)
                        }}>Limpar</Button>
                    </Grid>
        
                </Grid>

                <Grid container spacing = {2} style={{marginTop:'3px'}}>

                    <Grid item xs = {12}>
                        <Button size='large'  fullWidth variant='contained' color='default' onClick={()=>setOpen(!open)}>Fechar</Button>
                    </Grid>
                </Grid>
           
          </div>
        </Drawer>
      </React.Fragment>
  </div>
}

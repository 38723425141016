import React, {useState, useEffect, useRef, useCallback} from 'react'
import CaretIcon from '@material-ui/icons/ArrowDropUp'
import FilterIconUp from '@material-ui/icons/ArrowDropUp'
import {Button, Typography, Grid, TextField, InputAdornment, Switch,Checkbox, Divider} from '@material-ui/core'
import './ModalFltroPapercut.css'
import { Tooltip } from '@material-ui/core'
import {getDataRepository, substituirColetaEditada,atualizarEquipamentos, listarClientesEquipamentos} from '../../providers/Repository'
import ClearIcon from '@material-ui/icons/Clear';
import CachedIcon from '@material-ui/icons/Cached'
import SearchIcon from '@material-ui/icons/Filter2';
import ClientIcon from '@material-ui/icons/Domain'
import PrintOutlined from '@material-ui/icons/PrintOutlined'
import ModalOpcaoRelatorio from './ModalOpcaoRelatorio'
import {RelatorioVisaoParque} from './Relatorios/RelatorioVisaoParque'
import {RelatorioVisaoSemConexao} from './Relatorios/RelatorioVisaoSemConexao'
import {RelatorioVisaoOnline} from './Relatorios/RelatorioVisaoOnline'
import {RelatorioVisaoDesativados} from './Relatorios/RelatorioVisaoDesativados'
import {RelatorioVisaoDescobertos} from './Relatorios/RelatorioVisaoDescobertos'
import {RelatorioParqueCompleto} from './Relatorios/RelatorioParqueCompleto'
import {RelatorioParque} from './Relatorios/RelatorioParque'
import { RelatorioVisaoAletasSuprimentos } from './Relatorios/RelatorioVisaoAletasSuprimentos'
import {RelatorioVisaoTrocasPrematuras} from './Relatorios/RelatorioVisaoTrocasPrematuras'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Swal from 'sweetalert2'
import BotaoMenuRelatorios from './BotaoMenuRelatorios'
import BotaoMenuDownload from './Relatorios/BotaoMenuDownload'
import BotaoMenuFiltros from './BotaoMenuFiltros'
import {withRouter} from 'react-router-dom'
import IconePDF from './Componentes/pdf.png'
import IconeCSV from './Componentes/csv.png'
import md5 from 'md5'
import Conexao from '../../providers/Conexao'

pdfMake.vfs = pdfFonts.pdfMake.vfs;


function ModalFiltroPapercutRelatorios(props){
    const [showFilter, setShowFilter] = useState(false)
    
   
    const [marca, setMarca]= useState('')
    const [modelo, setModelo]= useState('')
    const [enderecoIP, setEnderecoIP]= useState('')
    const [dataInicial, setDataInicial]= useState('')
    const [dataFinal, setDataFinal]= useState('')
    const [numeroSerie, setNumeroSerie]= useState('')
    const [tipoData, setTipoData]= useState('dataCadastro')
    const [registros, setRegistros]= useState([])
    const [codigoCliente, setCodigoCliente]= useState(-1)
    const [clientes, setClientes ] = useState([])
    const {colunaFiltro} = props;
    const [loading, setLoading] = useState(false)
    const [filter, setFilter]= useState(false)
    const [ativo, setAtivo]= useState(false)
    const [semConexao, setSemConexao]= useState(false)
    const [apenasDesconectados, setApenasDesconectados]= useState(false)
    const [primeiroAcesso, setPrimeiroAcesso]= useState(true)
    const [inativos, setInativos] = useState(false)
    const [tipoDataRelatorioDCA, setTipoDataRelatorioDCA] = useState('cadastro')
    const [razaoSocial, setRazaoSocial]= useState('')
    const [tokenAPI, setTokenAPI] = useState('');
    let reportRecord = null;

    useEffect(()=>{
        getClientesRevenda()
        setPrimeiroAcesso(false)
        setTokenAPI(sessionStorage.getItem('BarenToken'));
    },[])

   const resetFiltros = () =>{
            setMarca('')
            setModelo('')
            setEnderecoIP('')
            setNumeroSerie('')
            setDataFinal('')
            setDataInicial('')
            setFilter(false)
   }

   const getClientesRevenda = () =>{
    Conexao.get('relatoriosController/getClientesRevenda?idempresa='+props.chaveEmpresa).then(response=>{
        if(response.status ==200 && response.data.status =='OK'){
            setClientes(response.data.payload)
        }else{
            setClientes([]);
        }
    }).catch(error=>{
        setClientes([]);
    })
   }

    const setColetaRepositorio = (data) =>{
       
        

        substituirColetaEditada(data)
        .then(updatedData => {
            

            const temp = updatedData.filter(item => item.dias == true && (item.equipamentonovo =='f' && item.ativo =='t'))
           
            setRegistros(temp)
    
            props.setRegistrosFiltrados(temp);
           
        
        })
        .catch(error => {
            console.log('Error updating data:', error);
        });
        
    }


    useEffect(()=>{
        if(!primeiroAcesso){
            getData();
        }
        
    },[ativo])

    useEffect(()=>{
        if(codigoCliente > 0){
            var temp = clientes.filter(item => item.idcliente == codigoCliente );
            
            setRazaoSocial(temp[0].razaosocial);
        }
        
        
    },[codigoCliente])

    useEffect(()=>{
        if(!primeiroAcesso){
           // getData();
        }
        if(!semConexao){
            setApenasDesconectados(false)
        }
        
    },[semConexao])
    
    useEffect(()=>{
        if(!primeiroAcesso){
         //   getData();
           
        }
        
    },[apenasDesconectados])

    
    useEffect(()=>{
        if(!primeiroAcesso){
           // getData();
           
        }
        
    },[inativos])
    


    const getData = (dataType = 'aoVivo') =>{
        setLoading(true)
        reportRecord = null;
        getDataRepository(props.chaveEmpresa, props.dataType).then(data=>{
            props.setRegistros(data);
            let temp = [];
           
            switch(dataType){
                case 'aoVivo':
                    console.log('Passou na linha 127')
                    if(inativos){
                        console.log('Passou na linha 129')
                        if(semConexao){
                            console.log('Passou na linha 131')
                            if(!apenasDesconectados){
                                console.log('Passou na linha 133')
                                temp = data.filter(item => (item.equipamentonovo =='f' ) || item.ativo =='f' )
                            }else{
                                console.log('Passou na linha 136')
                                temp = data.filter(item => (item.dias == false && (item.equipamentonovo =='f' )) || item.ativo =='f' )
                            }
                            
                            console.log('Passou na linha 140')
                        }else{
                            temp = data.filter(item => (item.dias == true && (item.equipamentonovo =='f' && item.ativo == 't')) || item.ativo =='f' )
                            console.log('Passou na linha 143')
                        }

                    }else{
                        console.log('Passou na linha 147')
                        if(semConexao){
                            console.log('Passou na linha 149')
                            if(!apenasDesconectados){
                                console.log('Passou na linha 151')
                                temp = data.filter(item => (item.equipamentonovo =='f' && item.ativo == 't'))
                            }else{
                                console.log('Passou na linha 154')
                                temp = data.filter(item => item.dias == false && (item.equipamentonovo =='f' && item.ativo == 't') )
                            }
                            
                            console.log('Passou na linha 127')
                        }else{
                            temp = data.filter(item => item.dias == true && (item.equipamentonovo =='f' && item.ativo == 't') )
                            console.log('linha 114')
                        }
                    }
                    
                   
                    
                break;
                case 'visaoParqueCompleto':
                    setLoading(false)
                    renderRelatorio(data, 'visaoParqueCompleto')
                    return;
                    
                break;

                case 'visaoParqueCompletoStatus':
                    setLoading(false)
                    renderRelatorio(data, 'visaoParqueCompletoStatus')
                    return;
                    
                break;

                case 'relatorioOnline':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.dias == true && (item.equipamentonovo =='f' && item.ativo =='t')), 'onLine')
                    return;
                    
                break;
                case 'relatorioOffLine':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.dias == false && (item.equipamentonovo =='f' && item.ativo =='t')), 'offLine')
                    return;
                break;
                case 'relatorioNovos':
                 
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.equipamentonovo =='t' && item.ativo =='t'), 'relatorioNovos')
                    return;
                break;

                case 'relatorioDesabilitados':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.ativo =='f'), 'inativos')
                    return;
                break;

                case 'relatorioDescobertas':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.ativo =='t' && item.equipamentonovo =='t'),'descobertas' )
                    return;
                break;

                case 'relatorioOnline':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.dias == true && (item.equipamentonovo =='f' && item.ativo =='t')), 'onLine')
                    return;
                    
                break;
                case 'relatorioOffLine':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.dias == false && (item.equipamentonovo =='f' && item.ativo =='t')), 'offLine')
                    return;
                break;
                case 'relatorioNovos':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.equipamentonovo =='t' && item.ativo =='t'), 'relatorioNovos')
                    return;
                break;

                case 'relatorioDesabilitados':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.ativo =='f'), 'inativos')
                    return;
                break;

                case 'relatorioDescobertas':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.ativo =='t' && item.equipamentonovo =='t'),'descobertas' )
                    return;
                break;
                //----

                case 'relatorioOnline2':
                    setLoading(false)
                    exportToCsv(data.filter(item => item.dias == true && (item.equipamentonovo =='f' && item.ativo =='t')), 'onLine')
                    return;
                    
                break;
                case 'relatorioOffLine2':
                    setLoading(false)
                    exportToCsv(data.filter(item => item.dias == false && (item.equipamentonovo =='f' && item.ativo =='t')), 'offLine')
                    return;
                break;
                case 'relatorioNovos2':
                    setLoading(false)
                    exportToCsv(data.filter(item => item.equipamentonovo =='t' && item.ativo =='t'), 'Relatório de equipamentos desabilitados')
                    return;
                break;

                case 'relatorioDesabilitados2':
                    setLoading(false)
                    exportToCsv(data.filter(item => item.ativo =='f'), 'inativos')
                    return;
                break;

                case 'relatorioDescobertas2':
                    setLoading(false)
                    exportToCsv(data.filter(item => item.ativo =='t' && item.equipamentonovo =='t'),'relatorioDescobertas2' )
                    return;
                break;

                case 'visaoParqueCompleto2':
                    setLoading(false)
                    exportToCsv(codigoCliente > -1 ? data.filter(item=> item.idcliente == codigoCliente) : data,'visaoParqueCompleto2' )
                    return;
                break;

                case 'visaoParqueCompleto2Status':
                    setLoading(false)
                    exportToCsv(codigoCliente > -1 ? data.filter(item=> item.idcliente == codigoCliente) : data,'visaoParqueCompleto2Status' )
                    return;
                break;

                case 'todas':
                    setLoading(false)                    
                break;
            }
            
            
            setRegistros(temp)
    
            props.setRegistrosFiltrados(codigoCliente > -1 ? temp.filter(item=> item.idcliente == codigoCliente) : temp);
           
            const clientesLista = listarClientesEquipamentos(data);
            
            setClientes(clientesLista);



            setTimeout(()=>{
                setLoading(false)
            },1500)
        }).catch(error=>{

            console.log('Erro repository ' + error)

            setLoading(false)
        })
        
       
        
       
    }


    const searchEquipmento = ( pEndereco, pDataInicial, pdataFinal,  pMarca, pModelo, pNumeroSerie, tipoData) => {
       
        if(!registros){
            return;
        }
        let  dataTemp = null
        setLoading(true)
        if(codigoCliente != '-1'){
            dataTemp = registros.filter(item => item.idcliente == codigoCliente);
        }else{
            dataTemp = registros;
        }
       
        const temp =  dataTemp.filter(function (equipamento) {
            const equipamentoEnderecoIp = equipamento.enderecoip.toLowerCase();
            const equipamentoDataCadastro = equipamento.datacadastro.toLowerCase();
            const equipamentoDataColeta = equipamento.datacoleta.toLowerCase();
            const equipamentoMarca = equipamento.marca.toLowerCase();
            const equipamentoModelo = equipamento.modelo.toLowerCase();
            const equipamentoNumeroSerie = equipamento.numeroserie.toLowerCase();
            const equipamentoCodigoCliente = equipamento.idcliente;
    
            const searchEnderecoIp = (pEndereco || "").toLowerCase();
            const searchDataCadastro = (pDataInicial || "").toLowerCase();
            const searchDataColeta = (pdataFinal || "").toLowerCase();
            const searchMarca = (pMarca || "").toLowerCase();
            const searchModelo = (pModelo || "").toLowerCase();
            const searchNumeroSerie = (pNumeroSerie || "").toLowerCase();
         
    
            // Check if the equipment matches all the provided search criteria
           

            return (
            equipamentoEnderecoIp.includes(searchEnderecoIp) && equipamentoDataColeta.includes(searchDataColeta) && equipamentoMarca.includes(searchMarca) &&
            equipamentoModelo.includes(searchModelo) && equipamentoDataCadastro.includes(searchDataCadastro) && equipamentoNumeroSerie.includes(searchNumeroSerie) 
            
            );
        });



     
        props.setRegistrosFiltrados(temp)

        setTimeout(()=>{
            setLoading(false)
        },500)

        
    }

    const getFilterData = (value, option = '') =>{
      
        const palavraChave = value
        if(registros.length == 0){
            return;
        }
        setLoading(true)
        props.setRegistrosFiltrados([])
        let tempData = null
        if(option == 'codigoCliente'){
            if(value == '-1'){
                props.setRegistrosFiltrados(registros);
                setTimeout(()=>{
                    setLoading(false)
                },500)
                return
            }
       
            tempData = registros.filter(function(equipamento) {
                return equipamento.idcliente == value;
            })
            props.setRegistrosFiltrados(tempData);
            setTimeout(()=>{
                setLoading(false)
            },500)
           return;
        }

        

            
        
    }

   

    const renderRelatorio = (reportData, layout ='default') =>{
       
     
        if( layout == 'visaoAlertasSuprimentos'){
            Swal.fire({
                title : 'Datacount Collector', 
                text : 'Aguarde...pesquisando dados...',
                icon:'info', 
                showConfirmButton:false, 
                showCancelButton:false, 
                
            })
            props.Conexao.post('coletasController/getAlertasSuprimentos', {
                idempresa:props.chaveEmpresa, 
                idcliente: codigoCliente
            }).then(response=>{
                if(response.status == 200 && response.data.status =='OK'){
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : response.data.payload, 
                            title : codigoCliente > 0 ? response.data.payload[0].razaosocial : 'Todos os clientes'
                        }
        
                        const classeAletasSuprimentos = new RelatorioVisaoAletasSuprimentos(parametrosImpressao) 
        
                        classeAletasSuprimentos.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioParque.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                }else{
                    Swal.fire({
                        title : 'Datacount Collector', 
                        text : 'Não existem informações para exibição',
                        icon:'warning', 
                        showConfirmButton:false, 
                        showCancelButton:false, 
                        timer : 1500
                    })
                }
    
            }).catch(error=>{
                Swal.fire({
                    title : 'Datacount Collector', 
                    text : 'Não existem informações para exibição',
                    icon:'warning', 
                    showConfirmButton:false, 
                    showCancelButton:false, 
                    timer : 1500
                })
            })
            return
        }


        if( layout == 'visaoTrocasPrematuras'){
            Swal.fire({
                title : 'Datacount Collector', 
                text : 'Aguarde...pesquisando dados...',
                icon:'info', 
                showConfirmButton:false, 
                showCancelButton:false, 
                
            })
            props.Conexao.post('coletasController/getTrocasToner', {
                idempresa:props.chaveEmpresa, 
                idusuario: props.codigoRevenda,
                idcliente: codigoCliente
            }).then(response=>{
                if(response.status == 200 && response.data.status =='OK'){
                    const data = response.data.payload.filter(item => (((item.contadortotalgeral != item.novocontadortotalgeral) || (item.nivel != item.nivelnovo) || (item.serialtoneranterior != item.serialtonernovo) ) && item.nivel > 0));

                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : data, 
                            title : codigoCliente > 0 ? data[0].razaosocial : 'Todos os clientes'
                        }
        
                        const classeAletasSuprimentos = new RelatorioVisaoTrocasPrematuras(parametrosImpressao) 
        
                        classeAletasSuprimentos.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioParque.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                }else{
                    Swal.fire({
                        title : 'Datacount Collector', 
                        text : 'Não existem informações para exibição',
                        icon:'warning', 
                        showConfirmButton:false, 
                        showCancelButton:false, 
                        timer : 1500
                    })
                }
    
            }).catch(error=>{
                Swal.fire({
                    title : 'Datacount Collector', 
                    text : 'Não existem informações para exibição',
                    icon:'warning', 
                    showConfirmButton:false, 
                    showCancelButton:false, 
                    timer : 1500
                })
            })
            return
        }

        if( !reportData || reportData.length == 0){
            Swal.fire({
                title : 'Datacount Collector', 
                text : 'Não existem informações para exibição',
                icon:'warning', 
                showConfirmButton:false, 
                showCancelButton:false, 
                timer : 1500
            })
            return
        }


        

        if( !reportData || reportData.length == 0){
            Swal.fire({
                title : 'Datacount Collector', 
                text : 'Não existem informações para exibição',
                icon:'warning', 
                showConfirmButton:false, 
                showCancelButton:false, 
                timer : 1500
            })
            return
        }


        
        try{

            Swal.fire({
                title : 'Datacount Collector', 
                text : 'Aguarde...carregando documento...',
                icon:'info', 
                showConfirmButton:false, 
                showCancelButton:false
            })

            if(layout !== 'visaoAlertasSuprimentos' &&  codigoCliente > -1){
                //props.setRegistrosFiltrados(codigoCliente > -1 ? temp.filter(item=> item.idcliente == codigoCliente) : temp);
                if(reportData && reportData.length > 0){
                    reportData = reportData.filter(item=> item.idcliente == codigoCliente)
                }                
            }

            if(!reportData || reportData.length == 0){
                Swal.fire({
                    title : 'Datacount Collector', 
                    text : 'Não existem dados para exibir',
                    icon:'warning', 
                    showConfirmButton:false, 
                    showCancelButton:false,
                    timer :  2000
                })
                return;

            }
            switch(layout){
                case 'default':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const classeImpressao = new RelatorioVisaoParque(parametrosImpressao) 
        
                        classeImpressao.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioParque.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;
                case 'onLine':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const visaoConectados = new RelatorioVisaoOnline(parametrosImpressao) 
        
                        visaoConectados.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioSemConexao.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;

                case 'offLine':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const visaoSemConexao = new RelatorioVisaoSemConexao(parametrosImpressao) 
        
                        visaoSemConexao.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioSemConexao.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;

                case 'inativos':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const visaoInativos = new RelatorioVisaoDesativados(parametrosImpressao) 
        
                        visaoInativos.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioSemConexao.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;

                case 'relatorioNovos':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const visaoDescobertas = new RelatorioVisaoDescobertos(parametrosImpressao) 
        
                        visaoDescobertas.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioSemConexao.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;

                case 'visaoParqueCompleto':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const parqueCompleto = new RelatorioParque(parametrosImpressao) 
        
                        parqueCompleto.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioParqueCompleto.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;

                case 'visaoParqueCompletoStatus':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const parqueCompleto = new RelatorioParqueCompleto(parametrosImpressao) 
        
                        parqueCompleto.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioParqueCompleto.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;


                case 'visaoAlertasSuprimentos':
                    
                   
                   /* setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const parqueCompleto = new RelatorioParqueCompleto(parametrosImpressao) 
        
                        parqueCompleto.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioParqueCompleto.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));*/
                break;



                
            }
            

            }catch(e){
                console.log(e)
                Swal.close()      
            }

    }


        //https://theroadtoenterprise.com/blog/how-to-download-csv-and-json-files-in-react
   const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
  
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  
  const exportToJson = (usersData) => {
   
    downloadFile({
      data: JSON.stringify(usersData),
      fileName: 'relatorioColetas.json',
      fileType: 'text/json',
    })
  }
  
  const exportToCsv = (userData, tipo = '') => {
    if(!userData || userData.length == 0){
        Swal.fire({
            title : 'Datacount Collector', 
            text : 'Não existem informações para exportação',
            icon:'warning', 
            showConfirmButton:false, 
            showCancelButton:false, 
            timer : 1500
        })
        return;
    }
   
    if(tipo == 'relatorioDescobertas2'){
        let headers = ['Endereco IP ,Data Cadastro, Hora Cadastro, Razao Social, Marca, Modelo, Numero Serie, ID Dca']
  
        // Convert users data to a csv
        let usersCsv = userData.reduce((acc, user) => {
          const { enderecoip, datacadastro, horacadastro, razaosocial, marca, modelo, numeroserie, iddca} = user
          acc.push([enderecoip, datacadastro, horacadastro.substr(0,8),razaosocial,  marca, modelo, numeroserie,iddca].join(','))
          return acc
        }, [])
      
        downloadFile({
          data: [...headers, ...usersCsv].join('\n'),
          fileName: 'users.csv',
          fileType: 'text/csv',
        })
    }else if(tipo == 'visaoParqueCompleto2'){
        let headers = ['Endereco IP ,Data Cadastro, Hora Cadastro,Data Coleta, Hora Coleta, Razao Social, Marca, Modelo, Numero Serie, ID Dca, Status Coleta']
  
        // Convert users data to a csv
        let usersCsv = userData.reduce((acc, user) => {
            const { enderecoip, datacadastro, horacadastro, datacoleta, horacoleta, razaosocial, marca, modelo, numeroserie, iddca, ativo} = user
            acc.push([enderecoip, datacadastro, horacadastro.substr(0,8),  datacoleta.substr(0,8), horacoleta, razaosocial,  marca, modelo, numeroserie,iddca, ativo == 't' ? 'ATIVO' : 'DESABILITADO'].join(','))
          return acc
        }, [])
      
        downloadFile({
          data: [...headers, ...usersCsv].join('\n'),
          fileName: 'relatorioColetas.csv',
          fileType: 'text/csv',
        })
    }else{
        let headers = ['Endereco IP ,Data Coleta, Hora Coleta, Razao Social, Numero de Serie, Marca, Modelo, A4 Mono, A3 Mono, Total Mono, A4 Color, A3 Color, Total Color, Total Geral, Scan 1, Scan 2']
  
        // Convert users data to a csv
        let usersCsv = userData.reduce((acc, user) => {
          const { enderecoip, datacoleta, horacoleta, razaosocial, numeroserie, marca, modelo, contadora4mono, contadora3mono, monototal, contadora4color, contadora3color, colortotal, contadortotal, contadormonoscanner, contadorcolorscanner, contadortotalscanner} = user
          acc.push([enderecoip, datacoleta, horacoleta,razaosocial, numeroserie, marca, modelo, contadora4mono, contadora3mono, monototal, contadora4color, contadora3color, colortotal, contadortotal, contadormonoscanner, contadorcolorscanner, contadortotalscanner].join(','))
          return acc
        }, [])
      
        downloadFile({
          data: [...headers, ...usersCsv].join('\n'),
          fileName: 'relatorioColetas.csv',
          fileType: 'text/csv',
        })
    }
  
    // Headers for each column
    
  }




    
    return <div style ={{marginTop : '-60px'}}>
        
    
        
        <Grid container spacing ={1} style ={{marginTop : '30px'}}>
           
            <Grid item xs ={2}>
                <TextField
                    label='Data Inicial'
                    fullWidth
                    type='date'
                    value={dataInicial}
                    onChange={(e)=>setDataInicial(e.target.value)}
                    variant='outlined'
                    InputLabelProps={{shrink:true}}/>
            </Grid>

            <Grid item xs ={2}>
                <TextField
                    label='Data Final'
                    fullWidth
                    type='date'
                    value={dataFinal}
                    onChange={(e)=>setDataFinal(e.target.value)}
                    variant='outlined'
                    InputLabelProps={{shrink:true}}/>
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    
                    label='Tipo de Data'
                    variant='outlined'
                    select
                    fullWidth
                    value={tipoDataRelatorioDCA}
                    onChange={(e)=>setTipoDataRelatorioDCA(e.target.value)}
                    InputLabelProps={{shrink:true}}
                    SelectProps={{native: true}}
                    >
                        <option >Cadastro</option>
                        <option >Ativação</option>
                        <option >Atualização</option>

                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    variant='outlined'
                    size='large'
                    label='Selecione o cliente'
                    fullWidth
                    placeholder='...'
                    InputLabelProps={{shrink:true}}
                    select
                    value={codigoCliente}
                    className='textField-busca'
                    SelectProps={{
                        native : true
                    }}
                    
                    onChange={(e)=>{
                        console.log(e.target.value)
                        setCodigoCliente(e.target.value)
                                  
                        
                    }}
                    >
                        <option value ='-1'>Todos</option>
                        {
                            clientes && clientes.map(function(cliente, i){
                                return <option value ={cliente.idcliente}>{cliente.razaosocial}</option>
                            })
                        }
                </TextField>
            </Grid>
            
            <Grid item xs={1} style ={{display :'flex'}}>
                    <Tooltip title='Limpar todas as opções de filtro'>
                        <ClearIcon style = {{color:'#546E7A', marginLeft:'5px', marginTop :'0px',fontSize :'54px', cursor:'pointer'}} className='icone-warning' onClick={()=>{
                           
                            setCodigoCliente(-1)
                            
                            
                            resetFiltros()
                        }}/>
                    </Tooltip>
                    <Tooltip title = 'Limpar filtro e buscar dados atualizados'>
                        <CachedIcon style = {{color:'#546E7A', marginLeft:'5px', marginTop :'0px',fontSize :'54px', cursor:'pointer'}} className={loading ? 'loading-spinner icone-success' : 'icone-success'} onClick={()=>{
                            //setCliente([]);
                            setCodigoCliente(-1)
                          
                            resetFiltros()
                        }}/>
                    </Tooltip>
            </Grid>
            <Grid item xs={1} style ={{display :'flex'}}>
                  {
                    props.disabled &&
                  <Button variant='contained' color='secondary' style ={{ width : '100%'}}  onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja Desabilitar o monitoramento deste(s) equipamento(s)?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resp=>{
                        if(resp.isConfirmed){
                            if(props.registrosDesativar.length == 0){
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Não existem equipamentos selecionados para processar', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'warning'
                                })
                                return
                            }

                            setLoading(true)
                            props.Conexao.post('coletasController/desativarListaColeta?idempresa',{
                                listaColetas :  props.registrosDesativar
                            }).then(response=>{
                                if(response.data.status =='OK'){
                                  
                                    atualizarEquipamentos(props.registrosDesativar,'desativarEquipamento', 'f').then(response=>{

                                        if(response && response.length > 0){

                                            setRegistros(response)

                                            props.setRegistrosFiltrados(codigoCliente > -1 ? response.filter(item=> item.idcliente == codigoCliente) : response);
                                            
                                            const clientesLista = listarClientesEquipamentos(response);
                                                
                                            setClientes(clientesLista);

                                            Swal.fire({
                                                title : 'Datacount', 
                                                text:'Operação realizada com sucesso !', 
                                                showCancelButton : false, 
                                                showConfirmButton : false, 
                                                timer  : 2500, 
                                                icon : 'success'
                                            })

                                        } else{
                                            setRegistros([])

                                            props.setRegistrosFiltrados([]);
                                            
                                            const clientesLista = listarClientesEquipamentos([]);
                                                
                                            setClientes(clientesLista);

                                            Swal.fire({
                                                title : 'Datacount', 
                                                text:'Operação realizada com sucesso ! Você será redirecionado agora...', 
                                                showCancelButton : false, 
                                                showConfirmButton : false, 
                                                timer  : 1500, 
                                                icon : 'success',
                                                onClose : ()=>{
                                                  // props.updateGrid()
                                                  props.history.push('/gestao_desabilitados/revenda/'+props.codigoRevenda+'/empresa/'+props.chaveEmpresa)
                                                }
                                            })
                                           
                                        }   

                                        setLoading(false)

                                    }).catch(error=>{
                                            Swal.fire({
                                                title : 'Datacount', 
                                                text:'Falha ao processar solicitação', 
                                                showCancelButton : false, 
                                                showConfirmButton : false, 
                                                timer  : 1500, 
                                                icon : 'warning',
                                                onClose : ()=>{
                                                    //props.history.push('/gestao_contadores/revenda/'+props.codigoRevenda+'/empresa/'+props.chaveEmpresa)
                                                }
                                            })
                                        setLoading(false)

                                    })


                                }else{
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação não realizada', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'warning'
                                    })
                                }
                            }).catch(error=>{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Ocorreu um erro inesperado !', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'error'
                                })
                            })
                        }
                    })
                   
                   }}>
                        desabilitar
                    </Button> 
}
                    
            </Grid>

            

            
            
        </Grid>
        <div className='div-form-container'>
            <Grid container spacing={2} >
                <Grid item xs = {2}>
                    <div className= {'container-card-relatorio'}>                        
                        <div className='container-card-relatorio-text'>
                            <Typography variant='subtitle1' align='center'>VISÃO DE PARQUE</Typography>
                            <Typography variant='caption' align='center' style ={{color: '#666', fontSize : '10px'}}>* Relação de todos os equipamentos cadastrados, incluindo: Descobertas, On Line, Off Line, Desabilitados, etc</Typography>
                        </div>
                        <Divider/>
                        <div className='container-card-relatorio-icon'>
                            <div className='container-card-icon'>
                                <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataVisaoParqueReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo='+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                                    <img src={IconePDF}  />
                                </a>
                            </div>
                            <div className='container-card-icon'>
                            <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataVisaoParqueReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo=excel'+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                            <img src={IconeCSV} />
                            </a>
                               
                            </div>
                        </div>
                        
                    </div>
                </Grid>

                


                <Grid item xs = {2}>
                    <div className='container-card-relatorio'>                        
                        <div className='container-card-relatorio-text'>
                            <Typography variant='subtitle1' align='center'>DESCOBERAS RECENTES</Typography>
                            <Typography variant='caption' align='center' style ={{color: '#666', fontSize : '10px'}}>* Relação de equipamentos descobertos aguarando ativação (autorizar monitoramento)</Typography>
                        </div>
                        <Divider/>
                        <div className='container-card-relatorio-icon'>
                            <div className='container-card-icon'>
                                <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataDescobertasReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo='+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                                    <img src={IconePDF}  />
                                </a>
                            </div>
                            <div className='container-card-icon'>
                            <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataDescobertasReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo=excel'+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                            <img src={IconeCSV} />
                            </a>
                               
                            </div>
                        </div>
                        
                    </div>
                </Grid>
                <Grid item xs = {2}>
                <div className='container-card-relatorio'>                        
                        <div className='container-card-relatorio-text'>
                            <Typography variant='subtitle1' align='center'>EQUIPAMENTOS ONLINE</Typography>
                            <Typography variant='caption' align='center' style ={{color: '#666', fontSize : '10px'}}>* Relação de equipamentos onLine</Typography>
                        </div>
                        <Divider/>
                        <div className='container-card-relatorio-icon'>
                            <div className='container-card-icon'>
                                <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataOnLineReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo='+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                                    <img src={IconePDF}  />
                                </a>
                            </div>
                            <div className='container-card-icon'>
                            <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataOnLineReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo=excel'+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                            <img src={IconeCSV} />
                            </a>
                               
                            </div>
                        </div>
                        
                    </div>
                </Grid>
                <Grid item xs = {2}>
                <div className='container-card-relatorio'>                        
                        <div className='container-card-relatorio-text'>
                            <Typography variant='subtitle1' align='center'>SEM CONEXÃO</Typography>
                            <Typography variant='caption' align='center' style ={{color: '#666', fontSize : '10px'}}>* Relação de equipamentos offLine (sem comunicação)</Typography>
                        </div>
                        <Divider/>
                        <div className='container-card-relatorio-icon'>
                            <div className='container-card-icon'>
                                <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataDesconectadasReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo='+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                                    <img src={IconePDF}  />
                                </a>
                            </div>
                            <div className='container-card-icon'>
                            <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataDesconectadasReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo=excel'+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                            <img src={IconeCSV} />
                            </a>
                               
                            </div>
                        </div>
                        
                    </div>
                </Grid>

                <Grid item xs = {2}>
                <div className='container-card-relatorio'>                        
                        <div className='container-card-relatorio-text'>
                            <Typography variant='subtitle1' align='center'>DESABILITADOS</Typography>
                            <Typography variant='caption' align='center' style ={{color: '#666', fontSize : '10px'}}>* Relação de equipamentos que estão com monitoramento desativado</Typography>
                        </div>
                        <Divider/>
                        <div className='container-card-relatorio-icon'>
                            <div className='container-card-icon'>
                                <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataDesabilitadasReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo='+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                                    <img src={IconePDF}  />
                                </a>
                            </div>
                            <div className='container-card-icon'>
                            <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataDesabilitadasReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo=excel'+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                            <img src={IconeCSV} />
                            </a>
                               
                            </div>
                        </div>
                        
                    </div>
                </Grid>

                <Grid item xs = {2}>
                    <div className= {'container-card-relatorio'}>                        
                        <div className='container-card-relatorio-text'>
                            <Typography variant='subtitle1' align='center'>STATUS DO PARQUE</Typography>
                            <Typography variant='caption' align='center' style ={{color: '#666', fontSize : '10px'}}>* Relação de todos os equipamentos cadastrados, incluindo: Descobertas, On Line, Off Line, Desabilitados, etc</Typography>
                        </div>
                        <Divider/>
                        <div className='container-card-relatorio-icon'>
                            <div className='container-card-icon'>
                                <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataStatusParqueReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo='+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                                    <img src={IconePDF}  />
                                </a>
                            </div>
                            <div className='container-card-icon'>
                            <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataStatusParqueReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo=excel'+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                            <img src={IconeCSV} />
                            </a>
                               
                            </div>
                        </div>
                        
                    </div>
                </Grid>
          
                <Grid item xs = {2}>
                    <div className={'container-card-relatorio'}>                        
                        <div className='container-card-relatorio-text'>
                            <Typography variant='subtitle1' align='center'>ALERTAS SUPRIMENTOS</Typography>
                            <Typography variant='caption' align='center' style ={{color: '#666', fontSize : '10px'}}>* Relação de todos os equipamentos cadastrados, incluindo: Descobertas, On Line, Off Line, Desabilitados, etc</Typography>
                        </div>
                        <Divider/>
                        <div className='container-card-relatorio-icon'>
                            <div className='container-card-icon'>
                                <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataAlertasSuprimentoReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo='+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                                    <img src={IconePDF}  />
                                </a>
                            </div>
                            <div className='container-card-icon'>
                            <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataAlertasSuprimentoReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo=excel'+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                            <img src={IconeCSV} />
                            </a>
                               
                            </div>
                        </div>                      
                    </div>
                </Grid>

                


                <Grid item xs = {2}>
                    <div className='container-card-relatorio'>                        
                        <div className='container-card-relatorio-text'>
                            <Typography variant='subtitle1' align='center'>TROCAS PREMATURAS</Typography>
                            <Typography variant='caption' align='center' style ={{color: '#666', fontSize : '10px'}}>* Relação de todos os equipamentos cadastrados, incluindo: Descobertas, On Line, Off Line, Desabilitados, etc</Typography>
                        </div>
                        <Divider/>
                        <div className='container-card-relatorio-icon'>
                            <div className='container-card-icon'>
                                <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataTrocasSuprimentoReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo='+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                                    <img src={IconePDF}  />
                                </a>
                            </div>
                            <div className='container-card-icon'>
                            <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataTrocasSuprimentoReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo=excel'+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                            <img src={IconeCSV} />
                            </a>
                               
                            </div>
                        </div>
                        
                    </div>
                </Grid>


                <Grid item xs = {2}>
                    <div className='container-card-relatorio' >                        
                    <div className='container-card-relatorio-text'>
                            <Typography variant='subtitle1' align='center'>SITUAÇÃO DCAs</Typography>
                            <Typography variant='caption' align='center' style ={{color: '#666', fontSize : '10px'}}>* visão geral do parque de DCAs cadastrados, instalados e comunicando.</Typography>
                        </div>
                        <Divider/>
                        <div className='container-card-relatorio-icon'>
                            <div className='container-card-icon'>
                                
                                <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataDcaStatusReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo='+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                                    <img src={IconePDF}  />
                                </a>
                            </div>
                            <div className='container-card-icon'>
                            <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataDcaStatusReport?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo=excel'+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                            <img src={IconeCSV} />
                            </a>
                               
                            </div>
                        </div>
                        
                    </div>
                </Grid>


                <Grid item xs = {2}>
                    <div className='container-card-relatorio' >                        
                    <div className='container-card-relatorio-text'>
                            <Typography variant='subtitle1' align='center'>CONTADORES DIÁRIOS</Typography>
                            <Typography variant='caption' align='center' style ={{color: '#666', fontSize : '10px'}}>* visão geral do parque de DCAs cadastrados, instalados e comunicando.</Typography>
                        </div>
                        <Divider/>
                        <div className='container-card-relatorio-icon'>
                            <div className='container-card-icon'>
                                {
                                    codigoCliente > -1 ? <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataContadoresDiarios?action='+tokenAPI+'&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo=&razaoSocial='+razaoSocial+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                                    <img src={IconePDF}  />
                                </a> : <Tooltip title='Selecione um cliente para acessar o relatório'><img src={IconePDF}  style={{opacity : codigoCliente > -1 ? 1 : 0.5}}/></Tooltip>
                                }
                                
                            </div>
                            <div className='container-card-icon'>
                                {
                                    codigoCliente > -1 ? <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataContadoresDiarios?action='+tokenAPI+btoa('&idempresa='+props.chaveEmpresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+tipoDataRelatorioDCA+'&idcliente='+codigoCliente+'&tipo=excel&razaoSocial='+razaoSocial)+"&oauth="+md5(codigoCliente+"#"+props.chaveEmpresa)} target='_blank'>
                                
                                    <img src={IconeCSV} />
                                </a> : <Tooltip title='Selecione um cliente para acessar o relatório'><img src={IconeCSV} style={{opacity : codigoCliente > -1 ? 1 : 0.5}}/></Tooltip>
                                }
                            
                               
                            </div>
                        </div>
                        
                    </div>
                </Grid>
{




                <Grid item xs = {2}>
                    <div className='container-card-relatorio' >                        
                    <div className='container-card-relatorio-text'>
                            <Typography variant='subtitle1' align='center'>MONITORADOS</Typography>
                            <Typography variant='caption' align='center' style ={{color: '#666', fontSize : '10px'}}>* Relatorio de equipamentos monitorados e os que estão ativos para faturamento Databit.</Typography>
                        </div>
                        <Divider/>
                        <div className='container-card-relatorio-icon'>
                            <div className='container-card-icon'>
                                {
                                    dataInicial != '' ? <a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataRelatoriosCobranca?action='+btoa("idusuario="+props.codigoRevenda+"&idcliente="+codigoCliente+"&tipo=&dataInicial="+dataInicial)+"&oauth=9f5c30eab8c4644b5a8da4ddcb263c75"} target='_blank'>
                                
                                    <img src={IconePDF}  />
                                </a> : <Tooltip title='Informe o período antes de continuar'><img src={IconePDF}  style={{opacity : dataInicial != '' ? 1 : 0.5}}/></Tooltip>
                                }
                                
                            </div>
                            <div className='container-card-icon'>
                                {
                                     dataInicial != ''  ? <a href= '#' >
                                
                                    <img src={IconeCSV} style={{opacity :  0.5}}/>
                                </a> : <Tooltip title='Informe o período antes de continuar'><img src={IconeCSV} style={{opacity :  0.5}}/></Tooltip>
                                }
                            
                               
                            </div>
                        </div>
                        
                    </div>
                </Grid>
}
                

            </Grid>
        </div>
       
               
                
            </div>
}

export default withRouter(ModalFiltroPapercutRelatorios)